//// grid
$em-factor: 0.0625;
$viewports: (phone: 320, phone-ls: 480, phablet: 560, tablet: 768, desktop: 992, desktop-lg: 1200);
$phone: (map-get($viewports, phone) * ($em-factor * 1em));
$phone-ls: (map-get($viewports, phone-ls) * ($em-factor * 1em));
$phablet: (map-get($viewports, phablet) * ($em-factor * 1em));
$tablet: (map-get($viewports, tablet) * ($em-factor * 1em));
$desktop: (map-get($viewports, desktop) * ($em-factor * 1em));
$desktop-lg: (map-get($viewports, desktop-lg) * ($em-factor * 1em));

//// typography
$t-base-font-size: 1.6rem;
$t-base-font-weight: 400;
$t-base-line-height: 1.4;
$t-space-line: $t-base-font-size * $t-base-line-height;
$t-base-font-family: 'Droid Serif', serif; // 400 + 700 + 400 italic + 700 italic
$t-icon-font-family: "FontAwesome";

//// color
$c-black: #000;
$c-white: #fff;
$c-catskill-white: #f0f3f7;
$c-emperor: #555;

$c-shiraz: #a90a2e;
$c-star-dust: #9d9d9c;
$c-sandal: #a4846c;

$c-1: $c-shiraz;
$c-2: $c-star-dust;
$c-3: $c-sandal;
$c-4: $c-black;
$c-5: $c-white;

$c-text: $c-black;
$c-link: $c-shiraz;
$c-link-hover: $c-shiraz;
$c-button: $c-white;
$c-button-bg: $c-shiraz;
$c-button-border: $c-shiraz;
$c-button-hover: $c-shiraz;
$c-button-bg-hover: $c-white;
$c-button-border-hover: $c-shiraz;
$c-button-disabled: $c-white;
$c-button-bg-disabled: $c-emperor;
$c-button-border-disabled: $c-emperor;
$c-input: $c-text;
$c-input-bg: $c-catskill-white;
$c-input-border: $c-catskill-white;
$c-input-hover: $c-text;
$c-input-bg-hover: $c-white;
$c-input-border-hover: $c-catskill-white;
$c-selection: lighten($c-shiraz, 25%);
$c-error: $c-shiraz;
$c-error-bg: rgba($c-shiraz, 0.25);

//// animation
$a-duration: 0.3s;
$a-function: ease;

//// layout
$l-input-padding-y: 0.8rem;
$l-input-padding-x: 1.2rem;
$l-input-padding: $l-input-padding-y $l-input-padding-x;
$l-input-radius: 0.3rem;
$l-input-border: 0.2rem;
$l-button-padding-y: 0.8rem;
$l-button-padding-x: 5rem;
$l-button-padding: $l-button-padding-y $l-button-padding-x;
$l-button-radius: $l-input-radius;
$l-button-border: 0.2rem;
