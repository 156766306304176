@mixin font-smoothing() {
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin rm-last-margin() {
  > *:last-child {
    margin-bottom: 0;
  }
}

@mixin media($query) {
  @media screen and (min-width: $query) {
    @content;
  }
}

@mixin button() {
  padding: $l-button-padding;
  margin: 0;
  font-size: $t-base-font-size;
  font-weight: 700;
  line-height: $t-base-line-height;
  color: $c-button;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;
  background-color: $c-button-bg;
  border: $l-button-border solid $c-button-border;
  border-radius: $l-button-radius;
  transition: all $a-duration $a-function;

  &:focus,
  &:hover {
    color: $c-button-hover;
    text-decoration: none;
    background-color: $c-button-bg-hover;
    outline: none;
  }

  &--disabled
  &:disabled,
  &[disabled] {
    color: $c-button-disabled;
    cursor: not-allowed;
    background-color: $c-button-bg-disabled;
    border-color: $c-button-border-disabled;

    &:focus,
    &:hover {
      color: $c-button-disabled;
      background-color: $c-button-bg-disabled;
    }
  }
}

@mixin input($type: "text") {
  width: 100%;
  padding: $l-input-padding;
  margin: 0;
  font-size: $t-base-font-size;
  font-weight: $t-base-font-weight;
  line-height: $t-base-line-height;
  color: $c-input;
  background-color: $c-input-bg;
  border: $l-input-border solid $c-input-border;
  border-radius: $l-input-radius;
  transition: all $a-duration $a-function;
  appearance: none;

  &:focus,
  &:hover {
    outline: none;
    color: $c-input-hover;
    background-color: $c-input-bg-hover;
    border-color: $c-input-border-hover;
  }

  @if $type == "text-area" {
    min-height: 5rem;
    max-width: 100%;
    resize: vertical;
  }
}
