.completed {
  width: 90%;
  margin: 5rem auto 0;

  @include media($tablet) {
    width: 80%;
  }

  @include media($desktop) {
    width: 70%;
  }

  @include media($desktop-lg) {
    width: 60%;
  }
}
