*, ::after, ::before {
  box-sizing: border-box;
}

body,
html {
  width: 100%;
  height: 100%;
  margin: 0;
}

html {
  font-size: $em-factor * 1000%;
}

body {
  @include font-smoothing();
  display: flex;
  flex-direction: column;
  font-family: $t-base-font-family;
  font-size: $t-base-font-size;
  color: $c-text;
}

a {
  display: inline-block;
  color: $c-link;
  text-decoration: none;

  &:active,
  &:focus,
  &:hover {
    color: $c-link-hover;
    text-decoration: underline;
  }
}

h1,
h2,
h3,
h4 {
  font-weight: 700;
  margin: 0 0 $t-space-line;
}

h1 {
  font-size: 4rem;
  color: $c-1;
}

h2 {
  font-size: 3.2rem;
  text-transform: uppercase;
}

h3 {
  font-size: 2.4rem;
}

h4 {
  font-size: 1.8rem;
}

p {
  margin: 0 0 $t-space-line;
}

.site-header {
  flex-shrink: 0;
  padding: 1rem 0;

  &__logo-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__logo {
    display: block;
    height: auto;

    &--left {
      width: 70%;

      @include media($phone-ls) {
        width: 60%;
      }

      @include media($tablet) {
        width: 40%;
      }

      @include media($desktop) {
        width: 30%;
      }
    }

    &--right {
      width: 17%;

      @include media($phone-ls) {
        width: 12%;
      }

      @include media($tablet) {
        width: 9%;
      }

      @include media($desktop) {
        width: 7%;
      }
    }
  }
}

.lang-nav {
  display: block;
  text-align: right;

  &__list {
    display: inline-block;
    list-style: none;
    padding: 1rem 0.5rem 0 0;
    margin: 0;

    @include media($desktop) {
      padding: 1rem 0.6rem 0 0;
    }

    @include media($desktop-lg) {
      padding: 1rem 1.3rem 0 0;
    }
  }

  &__item {
    display: inline-block;

    &:after {
      padding-left: 0.3rem;
      color: $c-2;
      content: "|";
    }

    &:last-child:after {
      padding-right: 0;
      content: none;
    }
  }

  &__link {
    color: $c-2;

    &--active {
      color: $c-link;
    }
  }
}

.site-main {
  flex: 1 0 auto;
}

.site-footer {
  position: relative;
  flex-shrink: 0;
  padding: 0.8rem 0;
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 1.1;
  color: $c-text;

  &__item {
    text-align: center;

    &--powered {
      margin-top: 1rem;
      font-size: 1.2rem;

      a {
        color: $c-text;

        &:active,
        &:focus,
        &:hover {
          color: $c-text;
        }
      }
    }
  }
}

