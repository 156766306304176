*, ::after, ::before {
  box-sizing: border-box;
}

body,
html {
  width: 100%;
  height: 100%;
  margin: 0;
}

html {
  font-size: 62.5%;
}

body {
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  font-family: "Droid Serif", serif;
  font-size: 1.6rem;
  color: #000;
}

a {
  display: inline-block;
  color: #a90a2e;
  text-decoration: none;
}

a:active, a:focus, a:hover {
  color: #a90a2e;
  text-decoration: underline;
}

h1,
h2,
h3,
h4 {
  font-weight: 700;
  margin: 0 0 2.24rem;
}

h1 {
  font-size: 4rem;
  color: #a90a2e;
}

h2 {
  font-size: 3.2rem;
  text-transform: uppercase;
}

h3 {
  font-size: 2.4rem;
}

h4 {
  font-size: 1.8rem;
}

p {
  margin: 0 0 2.24rem;
}

.site-header {
  flex-shrink: 0;
  padding: 1rem 0;
}

.site-header__logo-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.site-header__logo {
  display: block;
  height: auto;
}

.site-header__logo--left {
  width: 70%;
}

@media screen and (min-width: 30em) {
  .site-header__logo--left {
    width: 60%;
  }
}

@media screen and (min-width: 48em) {
  .site-header__logo--left {
    width: 40%;
  }
}

@media screen and (min-width: 62em) {
  .site-header__logo--left {
    width: 30%;
  }
}

.site-header__logo--right {
  width: 17%;
}

@media screen and (min-width: 30em) {
  .site-header__logo--right {
    width: 12%;
  }
}

@media screen and (min-width: 48em) {
  .site-header__logo--right {
    width: 9%;
  }
}

@media screen and (min-width: 62em) {
  .site-header__logo--right {
    width: 7%;
  }
}

.lang-nav {
  display: block;
  text-align: right;
}

.lang-nav__list {
  display: inline-block;
  list-style: none;
  padding: 1rem 0.5rem 0 0;
  margin: 0;
}

@media screen and (min-width: 62em) {
  .lang-nav__list {
    padding: 1rem 0.6rem 0 0;
  }
}

@media screen and (min-width: 75em) {
  .lang-nav__list {
    padding: 1rem 1.3rem 0 0;
  }
}

.lang-nav__item {
  display: inline-block;
}

.lang-nav__item:after {
  padding-left: 0.3rem;
  color: #9d9d9c;
  content: "|";
}

.lang-nav__item:last-child:after {
  padding-right: 0;
  content: none;
}

.lang-nav__link {
  color: #9d9d9c;
}

.lang-nav__link--active {
  color: #a90a2e;
}

.site-main {
  flex: 1 0 auto;
}

.site-footer {
  position: relative;
  flex-shrink: 0;
  padding: 0.8rem 0;
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 1.1;
  color: #000;
}

.site-footer__item {
  text-align: center;
}

.site-footer__item--powered {
  margin-top: 1rem;
  font-size: 1.2rem;
}

.site-footer__item--powered a {
  color: #000;
}

.site-footer__item--powered a:active, .site-footer__item--powered a:focus, .site-footer__item--powered a:hover {
  color: #000;
}

.form {
  width: 100%;
  margin-bottom: 2.24rem;
}

.form > *:last-child {
  margin-bottom: 0;
}

.form__group {
  width: 100%;
  margin-bottom: 2.24rem;
}

@media screen and (min-width: 62em) {
  .form__group--range {
    display: flex;
    align-items: center;
    min-height: 5rem;
  }
}

.form__group--right {
  text-align: right;
}

.form__text {
  width: 100%;
}

@media screen and (min-width: 62em) {
  .form__text {
    width: 60%;
    padding-right: 2rem;
    margin-bottom: 0;
  }
}

.form__text__title {
  font-weight: 700;
}

.form__input-group {
  width: 100%;
  margin-bottom: 2.24rem;
}

@media screen and (min-width: 62em) {
  .form__input-group {
    width: 40%;
    margin-bottom: 0;
  }
}

.form__input {
  width: 100%;
  padding: 0.8rem 1.2rem;
  margin: 0;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.4;
  color: #000;
  background-color: #f0f3f7;
  border: 0.2rem solid #f0f3f7;
  border-radius: 0.3rem;
  transition: all 0.3s ease;
  appearance: none;
}

.form__input:focus, .form__input:hover {
  outline: none;
  color: #000;
  background-color: #fff;
  border-color: #f0f3f7;
}

.form__error {
  width: 100%;
  margin-top: 1.12rem;
  padding: 0.8rem 1.2rem;
  color: #a90a2e;
  background-color: rgba(169, 10, 46, 0.25);
  border-radius: 0.3rem;
}

.form__button {
  padding: 0.8rem 5rem;
  margin: 0;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.4;
  color: #fff;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;
  background-color: #a90a2e;
  border: 0.2rem solid #a90a2e;
  border-radius: 0.3rem;
  transition: all 0.3s ease;
}

.form__button:focus, .form__button:hover {
  color: #a90a2e;
  text-decoration: none;
  background-color: #fff;
  outline: none;
}

.form__button--disabled .form__button:disabled, .form__button[disabled] {
  color: #fff;
  cursor: not-allowed;
  background-color: #555;
  border-color: #555;
}

.form__button--disabled .form__button:disabled:focus, .form__button--disabled .form__button:disabled:hover, .form__button[disabled]:focus, .form__button[disabled]:hover {
  color: #fff;
  background-color: #555;
}

.range-slider {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.range-slider__range {
  flex-grow: 1;
  padding: 1rem 0;
  margin: 0;
  cursor: pointer;
  background: none;
  border-radius: 0;
  outline: none;
  appearance: none;
}

.range-slider__range:focus {
  outline: none;
}

.range-slider__range::-webkit-slider-runnable-track {
  height: 0.5rem;
  background: #f0f3f7;
}

.range-slider__range::-webkit-slider-thumb {
  width: 2rem;
  height: 2rem;
  margin-top: -0.7rem;
  cursor: pointer;
  border-radius: 50%;
  appearance: none;
}

.survey__group--1 .range-slider__range::-webkit-slider-thumb {
  background-color: #9d9d9c;
}

.survey__group--2 .range-slider__range::-webkit-slider-thumb {
  background-color: #a4846c;
}

.survey__group--3 .range-slider__range::-webkit-slider-thumb {
  background-color: #a90a2e;
}

.range-slider__range::-moz-range-track {
  background: #f0f3f7;
}

.range-slider__range::-moz-range-thumb {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  border: none;
  border-radius: 50%;
}

.survey__group--1 .range-slider__range::-moz-range-thumb {
  background-color: #9d9d9c;
}

.survey__group--2 .range-slider__range::-moz-range-thumb {
  background-color: #a4846c;
}

.survey__group--3 .range-slider__range::-moz-range-thumb {
  background-color: #a90a2e;
}

.range-slider__range::-ms-track {
  width: 100%;
  height: 0.5rem;
  padding: 1rem 0;
  color: transparent;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
}

.range-slider__range::-ms-fill-lower {
  background: #f0f3f7;
}

.range-slider__range::-ms-fill-upper {
  background: #f0f3f7;
}

.range-slider__range::-ms-thumb {
  width: 1.6rem;
  height: 1.6rem;
  margin-top: 0;
  cursor: pointer;
  border: none;
  border-radius: 50%;
}

.survey__group--1 .range-slider__range::-ms-thumb {
  background-color: #9d9d9c;
}

.survey__group--2 .range-slider__range::-ms-thumb {
  background-color: #a4846c;
}

.survey__group--3 .range-slider__range::-ms-thumb {
  background-color: #a90a2e;
}

.range-slider__value {
  position: relative;
  display: inline-block;
  width: 3rem;
  margin-left: 1rem;
  color: #fff;
  text-align: center;
  border-radius: 0 0.3rem 0.3rem 0;
}

.survey__group--1 .range-slider__value {
  background-color: #9d9d9c;
}

.survey__group--1 .range-slider__value:after {
  border-right-color: #9d9d9c;
}

.survey__group--2 .range-slider__value {
  background-color: #a4846c;
}

.survey__group--2 .range-slider__value:after {
  border-right-color: #a4846c;
}

.survey__group--3 .range-slider__value {
  background-color: #a90a2e;
}

.survey__group--3 .range-slider__value:after {
  border-right-color: #a90a2e;
}

.range-slider__value:after {
  position: absolute;
  top: 0;
  left: -0.7rem;
  width: 0;
  height: 0;
  border-top: 1.1rem solid transparent;
  border-right: 0.7rem solid transparent;
  border-bottom: 1.1rem solid transparent;
  content: "";
}

.admin .graph {
  display: block;
  width: 100%;
  height: auto;
}

.survey h1 {
  text-align: center;
}

.survey h2 {
  margin-bottom: 0;
  font-size: 3.8rem;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
}

.survey h2 small {
  display: block;
  color: #fff;
  text-transform: none;
}

.survey__group {
  margin-bottom: 2.24rem;
}

.survey__group__header {
  padding: 2.5rem 0;
  margin-bottom: 2.24rem;
}

.survey__group--1 .survey__group__header {
  background-color: #9d9d9c;
}

.survey__group--2 .survey__group__header {
  background-color: #a4846c;
}

.survey__group--3 .survey__group__header {
  background-color: #a90a2e;
}

.registration {
  width: 60%;
  margin: 5rem auto 0;
}

.completed {
  width: 90%;
  margin: 5rem auto 0;
}

@media screen and (min-width: 48em) {
  .completed {
    width: 80%;
  }
}

@media screen and (min-width: 62em) {
  .completed {
    width: 70%;
  }
}

@media screen and (min-width: 75em) {
  .completed {
    width: 60%;
  }
}

.result h1 {
  text-align: center;
}

.result h2 {
  margin-bottom: 0;
  font-size: 3.8rem;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
}

.result h2 small {
  display: block;
  color: #fff;
  text-transform: none;
}

.result__group {
  margin-bottom: 2.24rem;
}

.result__group__header {
  padding: 2.5rem 0;
  margin-bottom: 2.24rem;
}

.result__group--1 .result__group__header {
  background-color: #9d9d9c;
}

.result__group--2 .result__group__header {
  background-color: #a4846c;
}

.result__group--3 .result__group__header {
  background-color: #a90a2e;
}

.result__graph {
  display: block;
  max-width: 100%;
  margin: 0 auto;
}

@media screen and (min-width: 62em) {
  .result__graph {
    width: 700px;
  }
}

.result__graph_radar {
  display: block;
  max-width: 100%;
  margin: 0 auto;
}

.result__graph-call {
  margin-bottom: 0;
  text-align: center;
}

.result__download-wrap {
  text-align: center;
}

@media screen and (min-width: 48em) {
  .result__download-wrap {
    text-align: right;
  }
}

.result__legend {
  text-align: center;
}
