.result {
  $group1: #{&}__group--1;
  $group2: #{&}__group--2;
  $group3: #{&}__group--3;

  h1 {
    text-align: center;
  }

  h2 {
    margin-bottom: 0;
    font-size: 3.8rem;
    color: $c-5;
    text-align: center;
    text-transform: uppercase;

    small {
      display: block;
      color: $c-5;
      text-transform: none;
    }
  }

  &__group {
    margin-bottom: $t-space-line;

    &__header {
      padding: 2.5rem 0;
      margin-bottom: $t-space-line;

      #{$group1} & {
        background-color: $c-2;
      }

      #{$group2} & {
        background-color: $c-3;
      }

      #{$group3} & {
        background-color: $c-1;
      }
    }
  }

  &__graph {
    display: block;
    max-width: 100%;
    margin: 0 auto;

    @include media($desktop) {
      width: 700px;
    }
  }

  &__graph_radar {
    display: block;
    max-width: 100%;
    margin: 0 auto;
  }

  &__graph-call {
    margin-bottom: 0;
    text-align: center;
  }

  &__download-wrap {
    text-align: center;

    @include media($tablet) {
      text-align: right;
    }
  }

  &__legend {
    text-align: center;
  }
}
