.form {
  @include rm-last-margin();
  width: 100%;
  margin-bottom: $t-space-line;

  &__group {
    width: 100%;
    margin-bottom: $t-space-line;

    &--range {
      @include media($desktop) {
        display: flex;
        align-items: center;
        min-height: 5rem;
      }
    }

    &--right {
      text-align: right;
    }
  }

  &__text {
    width: 100%;

    @include media($desktop) {
      width: 60%;
      padding-right: 2rem;
      margin-bottom: 0;
    }

    &__title {
      font-weight: 700;
    }
  }

  &__input-group {
    width: 100%;
    margin-bottom: $t-space-line;

    @include media($desktop) {
      width: 40%;
      margin-bottom: 0;
    }
  }

  &__input {
    @include input();
  }

  &__error {
    width: 100%;
    margin-top: ($t-space-line / 2);
    padding: $l-input-padding;
    color: $c-error;
    background-color: $c-error-bg;
    border-radius: $l-input-radius;
  }

  &__button {
    @include button();
  }
}

.range-slider {
  $group1: #{".survey__group--1"};
  $group2: #{".survey__group--2"};
  $group3: #{".survey__group--3"};

  display: flex;
  align-items: center;
  flex-direction: row;

  &__range {
    $track-height: 0.5rem;
    $track-color: $c-input-bg;
    $handle-width: 2rem;
    $handle-height: $handle-width;

    flex-grow: 1;
    padding: 1rem 0;
    margin: 0;
    cursor: pointer;
    background: none;
    border-radius: 0;
    outline: none;
    appearance: none;

    &:focus {
      outline: none;
    }

    &::-webkit-slider-runnable-track {
      height: $track-height;
      background: $track-color;
    }

    &::-webkit-slider-thumb {
      width: $handle-width;
      height: $handle-height;
      margin-top: -0.7rem;
      cursor: pointer;
      border-radius: 50%;
      appearance: none;

      #{$group1} & {
        background-color: $c-2;
      }

      #{$group2} & {
        background-color: $c-3;
      }

      #{$group3} & {
        background-color: $c-1;
      }
    }

    &::-moz-range-track {
      background: $track-color;
    }

    &::-moz-range-thumb {
      width: $handle-width;
      height: $handle-height;
      cursor: pointer;
      border: none;
      border-radius: 50%;

      #{$group1} & {
        background-color: $c-2;
      }

      #{$group2} & {
        background-color: $c-3;
      }

      #{$group3} & {
        background-color: $c-1;
      }
    }

    &::-ms-track {
      width: 100%;
      height: $track-height;
      padding: 1rem 0;
      color: transparent;
      cursor: pointer;
      background: transparent;
      border-color: transparent;
    }

    &::-ms-fill-lower {
      background: $track-color;
    }

    &::-ms-fill-upper {
      background: $track-color;
    }

    &::-ms-thumb {
      width: $handle-width * 0.8;
      height: $handle-height * 0.8;
      margin-top: 0;
      cursor: pointer;
      border: none;
      border-radius: 50%;

      #{$group1} & {
        background-color: $c-2;
      }

      #{$group2} & {
        background-color: $c-3;
      }

      #{$group3} & {
        background-color: $c-1;
      }
    }
  }

  &__value {
    position: relative;
    display: inline-block;
    width: 3rem;
    margin-left: 1rem;
    color: $c-5;
    text-align: center;
    border-radius: 0 $l-input-radius $l-input-radius 0;

    #{$group1} & {
      background-color: $c-2;

      &:after {
        border-right-color: $c-2;
      }
    }

    #{$group2} & {
      background-color: $c-3;

      &:after {
        border-right-color: $c-3;
      }
    }

    #{$group3} & {
      background-color: $c-1;

      &:after {
        border-right-color: $c-1;
      }
    }

    &:after {
      position: absolute;
      top: 0;
      left: -0.7rem;
      width: 0;
      height: 0;
      border-top: 1.1rem solid transparent;
      border-right: 0.7rem solid transparent;
      border-bottom: 1.1rem solid transparent;
      content: "";
    }
  }
}
